import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import SimpleReactValidator from "simple-react-validator";

import { Link } from 'react-router-dom';
import RectangleLoader from '../../common/rectangleLoader';

import { addToTryAtHome, clearTryAtHomeData } from '../../../actions'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";


const TryAtHome = (props) => {

  const [showModal, setShowModal] = useState(false);
  const [clearDataModal, setClearDataModal] = useState(false);

  const handleShow = () => setShowModal(true);

  const handleclearDataModal = () => {
    setClearDataModal(true)
  };

  const [pincode, setPincode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);


  const cartIcon = <FontAwesomeIcon icon={faShoppingCart} />;


  useEffect(() => {
    checkItemOnTryAtHomeCart(props.itemSelected.id)
  }, []);

  const handleClose = () => {
    setShowModal(false)
    setPincode("");
    setErrorMessage("");
    validator.current.hideMessages();
  };

  const handleCloseResetDataModal = () =>{
    setClearDataModal(false);
  }

  const validator = useRef(new SimpleReactValidator());
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);


  const handleInputChange = (setter) => (e) => setter(e.target.value);


  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const img_fallback = (event) => {
    event.target.parentNode.childNodes.forEach(function (node) {
      if (node.nodeName == "IMG") node.src = window.no_image;
    });
  }


  //Axios call for checking stock and pincode and save to try@home cart and redirect to the page
  const initalVerificationCall = async () => {
    if (validator.current.allValid()) {
      setLoading(true);
      try {
        const response = await axios.post(`${global.postUrl}try_at_home/product/availability/check`, {
          pincode: pincode,
          sku: props.item.sku,
          variantId: props.itemSelected.id
        });
        if (response.data.success) {
          validator.current.hideMessages();
          props.addToTryAtHome(response.data);
          handleClose();
        } else {
          handleErrorResponse(response.data.reason);
        }
      } catch (error) {
        console.error('Error verifying data', error);
        setErrorMessage('An error occurred while verifying your data. Please try again later. We apologize for the inconvenience.');
      } finally {
        setLoading(false);
        setIsActive(true); // Stop loading
      }
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleErrorResponse = (reason) => {
    switch (reason) {
      case 'PINCODE_CHECK_FAILED':
        setErrorMessage("Sorry, this feature isn't available at the provided pincode. Please try another design. We apologize for the inconvenience.");
        break;
      case 'PRODUCT_NOT_FOUND':
        setErrorMessage('Sorry, this product is currently unavailable. Please select a different one. We apologize for the inconvenience.');
        break;
      case 'OUT_OF_STOCK':
        setErrorMessage("We're sorry, but this product is currently out of stock. Please select a different product. We apologize for the inconvenience.");
        break;
      case 'VALIDATION_ERROR':
        setErrorMessage('There was a validation error. Please review your input.');
        break;
      default:
        setErrorMessage('An unknown error occurred.');
    }
  };


  //Checking Product already added on Try@Home Cart
  const checkItemOnTryAtHomeCart = (product_id) => {
    props.tryAtHomeItems.forEach(element => {
      element.products.forEach(product => {
        product.variants.forEach(variant => {
          if (product.id === product_id) {
            setIsActive(true)
          }
        });
      });
    });
  };

  //Function for clearing the existing try@home cart to an empty cart
  const handleTryAtHomeClearData = () => {
    props.clearTryAtHomeData();
    setClearDataModal(false);
    setIsActive(false);
    handleShow();
  };

  console.log(props.tryAtHomeItems);

  const stage1 = () => {
    return (
      <div>
        <div className='text-center'>
          <h4 className="text-captialize uk-margin-remove ">Try on your favourite designs</h4>
          <h4>from the comfort of your home</h4>
          {isLoading && <div className="placeholder placeholder-thumb w-100"></div>}
          <img
            src="https://static.sunnydiamonds.com/images/others/try@home/try_at_home.png"
            className={`img-fluid rounded mx-auto d-block w-100 ${isLoading ? 'd-none' : ''}`}
            alt="try@home"
            onLoad={handleImageLoad}
            onError={img_fallback}
          />
        </div>
        <div className='text-center my-3'>
          {/* <h3 className='text-capitalize fw-bold mb-0'>Try At Home for free</h3> */}
          <div className='m-0 text-center'>
            *Enter your details to check availability. There is no obligation to buy.
          </div>
        </div>
        <div className='my-2'>
          <div className="uk-margin-small uk-margin-remove-horizontal">
            <div className="uk-flex uk-flex-center">
              <input
                type="tel"
                className="uk-input uk-text-center rounded"
                value={pincode}
                onChange={handleInputChange(setPincode)}
                placeholder='Pin Code*'
              />
            </div>
            {validator.current.message(
              "Pin Code",
              pincode,
              "required|number|min:6|max:6"
            )}
          </div>
          {errorMessage && <div className="alert alert-danger p-2">
            <i class="fa fa-exclamation-triangle"></i> {errorMessage}
          </div>}
          <div>
            <button
              type="button"
              className="uk-button uk-button-primary buy-now uk-align-center w-100 text-uppercase rounded"
              onClick={initalVerificationCall}
              disabled={loading}
            >
              {loading ? (
                <RectangleLoader />) : (
                'Submit'
              )}
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card bg-white rounded shadow text-dark p-3">
            <div className="d-flex flex-row justify-content-between my-3">
              <div>
                <h4 className="text-uppercase fw-bold mb-0">Try at Home</h4>
                <h5 className="small m-0">How it Works ?</h5>
              </div>
              {isActive ? (
                <div className="text-danger small">
                  <div>
                    This product is already in the try at <br />home cart against this {props.tryAtHomeItems[0].pincode} pincode.<br />
                  </div>
                  <div className='d-flex flex-row justify-content-between'>
                    <Link className="text-decoration uk-text-bold text-success" to={`${process.env.PUBLIC_URL}/try-at-home`}>
                      View Cart {cartIcon}
                    </Link>
                    <div className="text-dark" style={{ cursor: "pointer" }}
                      onClick={handleclearDataModal}
                    >
                      Try Another Pincode
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="uk-button uk-button-primary buy-now uk-width-1-1 rounded"
                    style={{ lineHeight: "30px" }}
                    onClick={handleShow}
                  >
                    Try At Home
                  </button>
                </div>
              )}

            </div>
            <div className="d-flex flex-md-row flex-column justify-content-between">
              <div className="card rounded shadow p-3 m-2">
                <div className="fw-bold mb-0 text-left">
                  <div style={{ fontWeight: "bolder", color: "green" }}>Book Your Free Appointment</div>
                </div>
                <p className="m-0 text-left"><small>At your chosen place, date & time</small></p>
              </div>
              <div className="card rounded shadow p-3 m-2">
                <div className="fw-bold mb-0 text-left">
                  <div style={{ fontWeight: "bolder", color: "#722257" }}>Try On at Your Leisure</div>
                </div>
                <p className="m-0 text-left"><small>Let us bring the store to you</small></p>
              </div>
              <div className="card rounded shadow p-3 m-2">
                <div className="fw-bold mb-0 text-left">
                  <div style={{ fontWeight: "bolder", color: "black" }}>Buy Only If You Love</div>
                </div>
                <p className="m-0 text-left"><small>There's no compulsion to buy</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Try@Home Checking Modal */}
      {showModal && (

        <div className="blurred-background uk-flex uk-flex-center uk-padding">
          <div className="bg-white shadow uk-padding uk-width-1-4@xl uk-width-2-4@l uk-width-3-5@m otp_popup rounded">
            <div className="uk-flex uk-flex-right">
              <svg
                onClick={() => { handleClose() }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="mx-1 text-secondary cursor-pointer"
                viewBox="0 0 16 16">
                <path
                  d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
              </svg>
            </div>
            <div>
              {stage1()}
            </div>
          </div>
        </div>
      )}

      {clearDataModal && (
        <div className="blurred-background uk-flex uk-flex-center uk-padding">
          <div className="bg-white shadow uk-padding uk-width-1-4@xl uk-width-2-4@l uk-width-3-5@m otp_popup rounded">
            <div className="uk-flex text-center">
              <h4 className="text-captialize uk-margin-remove ">Would you like to try with a different pincode?</h4>
            </div>
            <div className='my-3'>
              Switching to a different pincode will reset your selected items. Are you sure you want to proceed?
            </div>
            <div className='d-flex justify-content-between'>
              <button
                type="button"
                className="uk-button uk-button-secondary add-cart uk-width-1-1 rounded mx-3"
                style={{ lineHeight: "30px" }}
                onClick={handleCloseResetDataModal}
              >
                Cancel
              </button>
              <button
                type="button"
                className="uk-button uk-button-primary buy-now uk-width-1-1 rounded"
                style={{ lineHeight: "30px" }}
                onClick={handleTryAtHomeClearData}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tryAtHomeItems: state.tryAtHome.list,
  };
};

export default connect(mapStateToProps, { addToTryAtHome, clearTryAtHomeData })(TryAtHome);