import {
    ADD_TO_TRYATHOME,
    REMOVE_FROM_TRYATHOME,
    TRYATHOME_UPDATED,
    CLEAR_TRYATHOME_DATA
} from "../constants/ActionTypes";
import { toast } from "react-toastify";

export default function TryAtHomeReducer(
    state = {
        list: []
    },
    action
) {
    switch (action.type) {
        case ADD_TO_TRYATHOME:
            const { pincode, product, showroom } = action.payload;

            // Check if the pincode already exists
            const existingPincodeIndex = state.list.findIndex(item => item.pincode === pincode);

            let updatedList = state.list;

            if (existingPincodeIndex !== -1) {
                // Check if the product already exists for this pincode
                const existingProductIndex = state.list[existingPincodeIndex].products.findIndex(item => item.sku === product.sku);

                if (existingProductIndex !== -1) {
                    toast.warn("Product already added for this pincode");
                    return state; // No changes if product already exists
                }

                // If less than 4 products, add the new product
                if (state.list[existingPincodeIndex].products.length < 4) {
                    updatedList[existingPincodeIndex].products.push(product);
                    toast.success("Product added for this pincode");
                } else {
                    toast.warn("Maximum 4 products can be added for this pincode");
                }

                return {
                    ...state,
                    list: [...updatedList]
                };
            }

            // If pincode does not exist or new pincode in response, clear existing data and set new data
            if (state.list.length > 0) {
                // Remove existing data as pincode has changed
                updatedList = [];
                toast.info("Pincode changed, clearing existing data");
            }

            // Add new pincode and product
            const newItem = {
                pincode,
                products: [product],
                showroom
            };

            return {
                ...state,
                list: [...updatedList, newItem]
            };

        case TRYATHOME_UPDATED:
            state.list.forEach(oldItem => {
                action.payload.forEach(newItem => {
                    if (oldItem.product.id === newItem.product.id) {
                        oldItem.product.offerprice = newItem.product.offerprice;
                        oldItem.product.active = newItem.deleted ? false : true;
                    }
                });
            });
            return {
                ...state,
                list: [...state.list]
            };

        case REMOVE_FROM_TRYATHOME:
            return {
                list: state.list.filter(item => item.product.sku !== action.variants.sku || item.pincode !== action.pincode)
            };
        case CLEAR_TRYATHOME_DATA:
            return {
                ...state,
                list: [], // Reset the data to an empty array or your desired initial state
            };

        default:
            return state;
    }
}
