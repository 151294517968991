import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ImageSlider from './image-slider';
import Breadcrumbs from "../../new_template_common/breadcrumb/BreadCrumb";

import '../css/product-details.css'
import PriceBreakUp from "./price-breakup";
import ProductInfo from "./product-info";
import { element } from "prop-types";
import TryAtHome from "./try-at-home";

const ProductDetails = (props) => {

  const [pin, setPin] = useState({
    pincode: "",
    pinStatus: "",
    pintext: "",
    pincode_loading: false,
  })
  const [qty, setQty] = useState(1);
  const [copyText, setCopyText] = useState();
  const [Data, setData] = useState({
    inStock: "",
    images: [],
    variants: [],
  });
  const [isActive, setIsActive] = useState(false);

  const { item,
    BuynowClicked,
    symbol,
    onRemoveFromWishlistClicked,
    wishListedItem
  } = props;
  const { variants, images } = Data;

  const inr = new Intl.NumberFormat('en-IN', { style: 'decimal' })

  const colorArray = [...new Map(item.variants.map(item => [item.color, item])).values()];


  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const setPinChange = (event) => {

    event.persist()
    setPin(prevState => ({
      ...prevState, pincode: event.target.value,
    }))
    if (event.target.value.length == 6)
      checkPin(event)
    else
      setPin({ pinStatus: "" })
  }


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const variant_id = queryParams.get('variant_id')
    let selectedVariant = []
    let list = props.item.variants.map((item) => (item.id == variant_id))
    if (list.includes(true))
      selectedVariant = props.item.variants.filter(item => (item.id == variant_id))[0]
    else {
      window.history.replaceState({}, "", window.location.href);
      selectedVariant = props.item.variants[0]
    }
    checkItemOnWishlist(selectedVariant.id)
    setData(prevState => ({
      ...prevState,
      variants: selectedVariant,
      images: selectedVariant.image.split(","),
      inStock: props.item.made_to_order == "true" ? "Made to order" : "In Stock",
    }))
    if (selectedVariant.offerprice >= 49000)
      setPin({
        pinStatus: "COD available only for products below ₹49k",
        pintext: "text-info-color"
      })
  }, []);


  const checkItemOnWishlist = (product_id) => {
    wishListedItem.forEach(element => {
      if (element.variant.id === product_id) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    });
  }


  const minusQty = () => {
    if (qty > 1)
      setQty(qty - 1)
    else
      setQty(1)
  };

  const plusQty = (item) => {
    if (item.made_to_order == "false") {
      if (item.stock > qty) {
        setQty(qty + 1)
      } else
        setData(prevState => ({ ...prevState, inStock: "Out of Stock !" }))
    } else
      setQty(qty + 1)
  };

  const addToCart = item => {
    props.addCartToServer(props.cartItems)
    props.addToCartClicked(item, qty, item.selectedVariant);
    setQty(1)
  };

  const handleWishlist = item => {
    if (isActive === true) {
      setIsActive(false);
      item.selectedVariant = variants
      props.onRemoveFromWishlistClicked(item, item.selectedVariant)
    }
    else {
      setIsActive(true);
      item.selectedVariant = variants
      props.addToWishlistClicked(item, item.selectedVariant)
    }
  }

  const addToCompare = item => {
    props.addToCompareClicked(item, item.selectedVariant)
  }

  const changeVariant = vari => {
    setData(prevState => ({
      ...prevState,
      variants: vari,
      images: vari.image.split(",")
    }))
    checkItemOnWishlist(vari.id)
    window.history.replaceState({}, "", window.location.pathname + "?variant_id=" + vari.id);
    props.setVariant(vari);
  }

  /*Color selector variant function call*/

  const colorHandle = (event) => {
    let selectedItem = []
    selectedItem = props.item.variants.filter(item => (item.color == event.target.value && item.size == Data.variants.size))
    if (selectedItem.length == 0)
      selectedItem = props.item.variants.filter(item => (item.color == event.target.value))

    var elems = document.querySelectorAll(".color-button > button");
    [].forEach.call(elems, function (el) {
      el.classList.remove("active");
    });
    event.target.classList.add("active");
    changeVariant(selectedItem[0]);
  }

  /*Size selector variant function call*/

  const sizeHandle = (event) => {
    let selectedItem = []
    selectedItem = props.item.variants.filter(item => (item.color == Data.variants.color && item.size == event.target.value))
    if (selectedItem.length == 0)
      selectedItem = props.item.variants.filter(item => (item.size == event.target.value))
    changeVariant(selectedItem[0])
  }

  /* Offer Section showing function*/
  const offerSectionShowing = () => {
    if (Data.variants.offers)
      return (
        <>
          <div>
            <button className="red-discount uk-button-secondary">
              <span uk-icon="icon: credit-card" className="uk-margin-small-right"></span>
              <span className="normal-para">{Data.variants.offers}</span>
            </button>
          </div>
          <hr />
        </>
      )
  }

  const checkPin = (event) => {

    if (pin.pincode_loading) return;

    setPin(prevState => ({ ...prevState, pincode_loading: true }))
    if (props.item.variants[0].offerprice * (qty) <= 49000) {
      checkPinAPI(event.target.value)
    }
    else {
      setPin({
        pinStatus: "COD not available for this product",
        pintext: "text-danger",
        pincode_loading: false
      })
    }
  };

  const checkPinAPI = async (pin) => {
    await axios
      .get("https://api.postalpincode.in/pincode/" + pin)
      .then(response => {
        response = response.data
        setPin(prevState => ({ ...prevState, pincode_loading: false }))
        if (response[0].Status == "Success") {
          setPin({
            pinStatus: "Cash on delivery available in your pincode",
            pintext: "text-success",
            pincode_loading: false
          })
        }
        else {
          setPin({
            pinStatus: "Oops, Only available in selected pincodes",
            pintext: "text-danger",
            pincode_loading: false
          })
        }
      })
      .catch(error => {
        setPin(prevState => ({ ...prevState, pincode_loading: false }))
        if (error) {
          setPin({
            pinStatus: " Error cannot find. Please try again",
            pintext: "text-danger",
            pincode_loading: false
          })
        }
      });
  }

  const scrollToProductDetails = () => {
    var element = document.getElementById("sd-product-information")
    element.scrollIntoView({ behavior: 'smooth' });
  }

  const copyLink = () => {
    const textToCopy = "https://sunnydiamonds.com/" + props.item.value + "?variant_id=" + Data.variants.id;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopyText("copied");
        setTimeout(() => setCopyText(""), 2000);
      })
      .catch(error => {
        console.log("Copy failed: ", error);
      });
  }


  return (
    <div className="uk-section sd-inner-content uk-padding-small product-detail-main" style={{ width: "100vw", overflowX: "clip" }}>
      <div className="uk-container uk-container-xlarge">
        <Breadcrumbs
          ancestor={item.name}
          currentPage=""
        />
        <div data-uk-grid>
          {variants ? (
            <ImageSlider imageArr={images} itemName={item.name} />
          ) : (
            <ImageSlider imageArr={item.pictures} itemName={item.name} />
          )}
          <div className="uk-width-1-1 uk-width-1-2@m side-bar-listing ">
            <h2 className="title-normal text-left" style={{ paddingBottom: "0rem", paddingTop: "0.5rem" }}>{item.name.toUpperCase()}</h2>

            <div className="uk-flex">
              <div className="uk-margin-remove pdp-sku text-dark pr-2">SKU: {item.sku.toUpperCase()}</div>
              <div className="pl-2 border-left" style={{ paddingTop: "2px" }}>
                {variants.rating !== null && variants.rating > 0 ?
                  <>
                    {variants.rating}&nbsp;
                    <i className={"fa fa-star" + (variants.rating >= 1 ? "" : variants.rating > 0 && variants.rating < 1 ? "-half-o" : "-o")} color="#722257" aria-hidden="true" />
                    <i className={"fa fa-star" + (variants.rating >= 2 ? "" : variants.rating > 1 && variants.rating < 2 ? "-half-o" : "-o")} aria-hidden="true" />
                    <i className={"fa fa-star" + (variants.rating >= 3 ? "" : variants.rating > 2 && variants.rating < 3 ? "-half-o" : "-o")} aria-hidden="true" />
                    <i className={"fa fa-star" + (variants.rating >= 4 ? "" : variants.rating > 3 && variants.rating < 4 ? "-half-o" : "-o")} aria-hidden="true" />
                    <i className={"fa fa-star" + (variants.rating >= 5 ? "" : variants.rating > 4 && variants.rating < 5 ? "-half-o" : "-o")} aria-hidden="true" />
                  </>
                  : ""}
              </div>
            </div>
            <hr className="uk-margin-small" />
            <div className="d-none d-lg-block">
              <div className="uk-child-width-expand" data-uk-grid>
                <div className="uk-padding-remove uk-margin-small-top uk-margin-small-bottom">
                  <div style={{ fontWeight: "bolder", color: "green" }}>
                    {variants.offerprice ?
                      <span className="tw-text-2xl">{symbol} {inr.format(variants.offerprice)} </span>
                      :
                      <div data-uk-spinner></div>
                    }
                  </div>
                </div>

                <div className="uk-padding-remove uk-margin-small-top uk-margin-small-bottom d-flex align-items-end justify-content-end ">
                  <div className="px-2">
                    <PriceBreakUp symbol={symbol} product={variants} />
                  </div>
                </div>
              </div>
              <hr className="uk-margin-small" />
            </div>
            <div className="d-lg-none d-block">
              <div className="uk-flex uk-flex-between">
                <div style={{ fontWeight: "bolder", fontSize: "25px", color: "green" }}>
                  {variants.offerprice ?
                    <span className="tw-text-2xl">{symbol} {inr.format(variants.offerprice)} </span>
                    :
                    <div data-uk-spinner></div>
                  }
                </div>
                <div className="">
                  <PriceBreakUp symbol={symbol} product={variants} />
                </div>
              </div>
            </div>

            {offerSectionShowing()}

            {/* Desktop view for COD, Metal type selector and Size selector */}
            <div className="uk-width-1-1@l form-select-size d-none d-lg-block">
              <form onSubmit={handleSubmit}>
                <fieldset className="uk-fieldset">
                  <div className="uk-grid-small uk-child-width-expand@s" data-uk-grid>
                    <div className="uk-padding-remove">
                      <label className="uk-form-label" htmlFor="form-stacked-select">Check Cash On Delivery</label>
                      <div className="uk-child-width-1-1">
                        <div className="uk-inline">
                          <span className="uk-form-icon" data-uk-icon="icon: location"></span>
                          <input
                            type="Pincode"
                            className="uk-input"
                            placeholder="Pin Code"
                            minLength="6"
                            maxLength="6"
                            name="pincode"
                            value={pin.pincode}
                            onChange={setPinChange}
                            disabled={variants.offerprice > 49000}
                          />
                        </div>
                        {pin.pincode_loading && <span data-uk-spinner="ratio: 0.5" className="mt-2">Checking...</span>}
                        <h6 className={"product-title my-2 " + pin.pintext} style={{ fontSize: "12px" }}>{pin.pinStatus}</h6>
                      </div>
                    </div>
                    <div className="uk-margin-remove uk-flex uk-flex-column">
                      <label className="uk-form-label" htmlFor="form-stacked-select">Metal Color</label>
                      <div className="color-button uk-flex uk-display-inline ">
                        {
                          colorArray.map((element, i) => {
                            if (element.color === 'yellow-gold') {
                              return (
                                <button
                                  key={i}
                                  className={(element.id === variants.id) ? 'active mt-2 ms-2 round-1' : 'mt-2 ms-2 round-1'}
                                  onClick={(e) => colorHandle(e)}
                                  value={element.color}
                                >
                                </button>
                              );
                            } else if (element.color === 'rose-gold') {
                              return (
                                <button
                                  key={i}
                                  className={(element.id === variants.id) ? 'active mt-2 ms-2 round-2' : 'mt-2 ms-2 round-2'}
                                  onClick={(e) => colorHandle(e)}
                                  value={element.color}
                                >
                                </button>
                              );
                            } else if (element.color === 'white-gold') {
                              return (
                                <button
                                  key={i}
                                  className={(element.id === variants.id) ? 'active mt-2 ms-2 round-3' : 'mt-2 ms-2 round-3'}
                                  onClick={(e) => colorHandle(e)}
                                  value={element.color}
                                >
                                </button>
                              );
                            } else {
                              return (
                                <button
                                  key={i}
                                  className='round-3 mt-2 ms-2'
                                  onClick={(e) => colorHandle(e)}
                                  value={'yellow-gold'}
                                >
                                </button>
                              );
                            }
                          })
                        }
                      </div>
                    </div>

                    {item.category == 'rings' || item.category == 'bangles' || item.category == 'bracelets' ?
                      <div className="uk-margin-remove">
                        <label className="uk-form-label uk-flex" htmlFor="form-stacked-select" style={{ justifyContent: "space-between" }}>
                          Select Size <Link
                            to={`${process.env.PUBLIC_URL}/` + item.category != "rings" ? "education/size-chart/ring-size-guide" : "education/size-chart/bangle-and-bracelet-size-guide"}
                            style={{ color: "#722257" }}
                          >
                            <small>Size chart</small>
                          </Link>
                        </label>
                        <select
                          className="uk-select"
                          name="size"
                          onChange={(e) => sizeHandle(e)}
                          value={variants.size}
                        >
                          {item.variants.filter((item) => item.color == variants.color).map((vari, i) => (
                            <option key={i} value={vari.size}>{vari.size}</option>
                          ))}
                        </select>

                      </div> : ""}

                  </div>
                </fieldset>
              </form>
            </div>
            {/* Mobile and tab view for COD, Metal type selector and Size selector */}
            <div className="uk-flex uk-flex-column d-lg-none d-block">
              <form onSubmit={handleSubmit}>
                <fieldset className="uk-fieldset">
                  <div className="py-1">
                    <label className="uk-form-label" htmlFor="form-stacked-select">Check Cash On Delivery</label>
                    <div className="uk-child-width-1-1">
                      <div className="uk-inline">
                        <span className="uk-form-icon" data-uk-icon="icon: location"></span>
                        <input
                          type="Pincode"
                          className="uk-input"
                          placeholder="Pin Code"
                          minLength="6"
                          maxLength="6"
                          name="pincode"
                          value={pin.pincode}
                          disabled={variants.offerprice > 49000}
                          onChange={setPinChange}
                        />
                      </div>
                      {pin.pincode_loading && <div data-uk-spinner="ratio-1" className="uk-padding-small-top"></div>}
                      <h6 className={"product-title my-2 " + pin.pintext} style={{ fontSize: "12px" }}>{pin.pinStatus}</h6>
                    </div>
                  </div>
                  <div className="py-1">
                    <label className="uk-form-label" htmlFor="form-stacked-select">Metal Color</label>
                    <select
                      className="uk-select"
                      name="color"
                      onChange={(e) => colorHandle(e)}
                      value={variants.color}
                    >
                      {colorArray.map((vari, i) => (
                        <option key={i} value={vari.color} selected>
                          {vari.color.replaceAll("-", " ").toUpperCase()}
                        </option>
                      ))
                      }
                    </select>
                  </div>
                  {item.category == 'rings' || item.category == 'bangles' || item.category == 'bracelets' ?
                    <div className="py-2">
                      <label className="uk-form-label uk-flex" htmlFor="form-stacked-select" style={{ justifyContent: "space-between" }}>
                        Select Size
                        <Link
                          to={`${process.env.PUBLIC_URL}/` + item.category != "rings" ? "education/size-chart/ring-size-guide" : "education/size-chart/bangle-and-bracelet-size-guide"}
                          style={{ color: "#722257" }}
                        >
                          <small>Size chart</small>
                        </Link>
                      </label>
                      <select
                        className="uk-select"
                        name="size"
                        onChange={(e) => sizeHandle(e)}
                        value={variants.size}
                      >
                        {item.variants.filter((item) => item.color == variants.color).map((vari, i) => (
                          <option key={i} value={vari.size}>{vari.size}</option>
                        ))}
                      </select>

                    </div> : ""}
                </fieldset>
              </form>
            </div>

            <hr />

            {/* Desktop view for quantity selector, buy now button and add to cart button */}
            <div className="cart-button d-none d-lg-block">
              <div className="uk-child-width-1-1@s uk-child-width-1-3@m" data-uk-grid>
                <div className="uk-padding-remove">
                  <div className="quantity buttons_added">
                    <input
                      type="button"
                      value="-"
                      className="minus"
                      onClick={minusQty}
                    />
                    <input
                      type="number"
                      step="1"
                      min="1"
                      max=""
                      name="quantity"
                      value={qty}
                      title="Qty"
                      className="input-text qty text" size="4"
                      pattern=""
                      inputMode=""
                      readOnly
                    />
                    <input
                      type="button"
                      value="+"
                      className="plus"
                      onClick={() => plusQty(item)}
                    />
                  </div>
                </div>
                <div className="uk-visible@m">
                  <Link style={{ zIndex: 0 }}
                    to={`${process.env.PUBLIC_URL}/checkout`}
                    className="mx-1 uk-button uk-button-primary buy-now uk-width-1-1"
                    onClick={() => {
                      item.selectedVariant = variants
                      BuynowClicked(item, qty, item.selectedVariant)
                    }}
                  >
                    buy now
                  </Link>
                </div>
                <div className="uk-visible@m">
                  <button style={{ zIndex: 0 }}
                    className="mx-1 uk-button uk-button-secondary add-cart uk-width-1-1"
                    onClick={() => {
                      item.selectedVariant = variants
                      addToCart(item)
                    }}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>

            {/* Mobile and tab view for quantity selector and category button */}
            <div className="d-lg-none d-block">
              <div className="uk-flex uk-flex-between">
                <div className="quantity buttons_added">
                  <input
                    type="button"
                    value="-"
                    className="minus"
                    onClick={minusQty}
                  />
                  <input
                    type="number"
                    step="1"
                    min="1"
                    max=""
                    name="quantity"
                    value={qty}
                    title="Qty"
                    className="input-text qty text" size="4"
                    pattern=""
                    inputMode=""
                    readOnly
                  />
                  <input
                    type="button"
                    value="+"
                    className="plus"
                    onClick={() => plusQty(item)}
                  />
                </div>
                <div style={{ paddingTop: "0px", margin: "0px" }}>
                  <Link className="d-block"
                    to={`${process.env.PUBLIC_URL}/jewellery/` + item.category}
                    target="_blank" >
                    <span className="uk-label  rounded" style={{ backgroundColor: '#722257' }}
                    // style={{ paddingTop: "8px", paddingRight: "5px", textAlign: "center", width: "100%" }}
                    >
                      <svg width="1rem" height="1rem" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.0417 7L11.5833 4L14.5 5.90909L18 4.27273L15.6667 7.27273L17.125 10L14.2083 8.36364L11 9.72727L13.0417 7Z" fill="#fff"></path>
                        <path d="M2.75 15L1.5 12L4 13.9091L7 12.2727L5 15.2727L6.25 18L3.75 16.3636L1 17.7273L2.75 15Z" fill="#fff"></path>
                        <path d="M2.04167 3L0.583333 0L3.5 1.90909L7 0.272727L4.66667 3.27273L6.125 6L3.20833 4.36364L0 5.72727L2.04167 3Z" fill="#fff"></path>
                      </svg>
                      <span className="px-2 tw-text-xs tw-font-bold" >
                        Explore {item.category}
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <hr />

            {variants.features && variants.features.split(',').includes("try_@_home") && (
              <>
                <TryAtHome
                  item={item}
                  imageArray={images}
                  symbol={symbol}
                  itemSelected={props.itemSelected}
                />
                <hr />
              </>
            )} 

            {/* wishlist, share and compare component */}
            <div className="uk-flex uk-flex-between mb-3">
              <div>
                <a
                  href="javascript:void(0)"
                  className="wishlist-btn d-flex justify-content-between align-items-center btn-light border-0 px-3 rounded py-1"
                  onClick={() => handleWishlist(item)}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill={!isActive ? "#fff" : "#ed1313"} width="19" height="19" viewBox="0 0 24 24" strokeWidth="1" stroke={!isActive ? "currentColor" : "#ed1313"} className="">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"></path>
                  </svg>
                  &nbsp;<span>
                    <p className="uk-margin-remove text-small">Wishlist</p>
                  </span>
                </a>
              </div>
              <div>
                <a
                  href="javascript:void(0)"
                  className="wishlist-btn d-flex justify-content-between align-items-center btn-light border-0 px-3 rounded py-1"
                  onClick={() => {
                    item.selectedVariant = variants
                    addToCompare(item, item.selectedVariant)
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.6" stroke="#722257" width="17" height="17">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"></path>
                  </svg>
                  &nbsp;<span>
                    <p className="uk-margin-remove text-small">Compare</p>
                  </span>
                </a>
              </div>
              <div>
                <div>
                  <a href="javascript:void(0)" className="wishlist-btn d-flex justify-content-between align-items-center btn-light border-0 px-3 rounded py-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-share" viewBox="0 0 24 24">
                      <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                    </svg>
                    &nbsp;<span>
                      <p className="uk-margin-remove text-small">Share</p>
                    </span>
                  </a>
                  <div className="uk-card uk-card-default" data-uk-drop="mode: click">
                    <div className="uk-card-header p-3">
                      <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                        <div className="uk-width-expand">
                          <h3 className="uk-margin-remove-bottom fw-bold">Social Share</h3>
                          <p className="uk-text-meta uk-margin-remove-top text-muted">Share this link via</p>
                        </div>
                      </div>
                    </div>
                    <hr className="uk-margin-remove" />
                    <div className="uk-card-body p-3">
                      <div className="uk-flex uk-flex-between">
                        <div>
                          <a href={"https://www.facebook.com/sharer/sharer.php?u=https://sunnydiamonds.com/" + item.value + "?variant_id=" + variants.id}
                            target="_blank" className="rounded-circle share-facebook d-flex justify-content-center align-items-center">
                            <span data-uk-icon="facebook"></span>
                          </a>
                        </div>
                        <div>
                          <a href={"https://twitter.com/intent/tweet?url=https://sunnydiamonds.com/" + item.value}
                            target="_blank" className="rounded-circle share-twitter d-flex justify-content-center align-items-center">
                            <span data-uk-icon="twitter"></span>
                          </a>
                        </div>
                        <div>
                          <a href={"whatsapp://send?text=https://sunnydiamonds.com/" + item.value + "?variant_id=" + variants.id}
                            data-action="share/whatsapp/share"
                            target="_blank" className="rounded-circle share-whatsapp d-flex justify-content-center align-items-center">
                            <span data-uk-icon="whatsapp"></span>
                          </a>
                        </div>
                        <div>
                          <a
                            onClick={() => copyLink()}
                            className="rounded-circle share-copy d-flex justify-content-center align-items-center"
                          >
                            {copyText ?
                              <h6 className="m-0 uk-align-center">{copyText}</h6>
                              : <span data-uk-icon="copy"></span>
                            }
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {variants.price !== 0 ? (
              <div className="fair-pricing">
                <div className="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" data-uk-grid>
                  <div className="uk-text-left@l estimated-box uk-padding-remove">
                    <div className="uk-card-body uk-padding-small" style={{ background: "#fff" }}>
                      <span className="uk-text-capitalize" s>Fair Pricing</span>
                      <p className="para-one uk-margin-remove">{symbol} {inr.format(variants.offerprice)} Sunny Diamonds</p>
                    </div>
                  </div>
                  <div className="uk-text-right@l estimated-box uk-padding-remove">
                    <div className="uk-card-body uk-padding-small">
                      <a href={`${process.env.PUBLIC_URL}/fair-pricing`} target="_blank" className="learn-more">Learn More</a>
                      <p className="para-one uk-margin-remove uk-text-right@l">{symbol} {inr.format(variants.price)} Estimated Retail</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="uk-placeholder uk-text-center uk-padding-small">
              <div className="uk-text-center">
                <p className="uk-margin-remove tw-text-sm">Need help to find the best jewellery for you ?</p>
                <p className="uk-text-small uk-margin-remove tw-text-sm">We are available for your assistance</p>
              </div>
              <div className="uk-text-center uk-flex-center uk-margin-small-top" data-uk-grid>
                <div>
                  <div>
                    <a
                      href="tel:+919744355555"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="uk-icon-button"
                      data-uk-icon="icon: receiver"
                    >
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a
                      href="https://api.whatsapp.com/send?phone=919961870099"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="uk-icon-button"
                      data-uk-icon="icon: whatsapp"
                    >
                    </a>
                  </div>
                </div>
                <div>
                  <div>
                    <a
                      href="mailto:customerservice@sunnydiamonds.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="uk-icon-button"
                      data-uk-icon="icon: mail"
                    >
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div >
      </div>

      {/* mob-cart-button */}
      <div className="uk-grid-match uk-child-width-expand@s uk-text-center uk-hidden@m uk-position-relative" data-uk-grid>
        <div className="uk-child-width-1-2 fixed-buttons buy-cart-static">
          <Link style={{ zIndex: 1 }}
            to={`${process.env.PUBLIC_URL}/checkout`}
            className="mx-1 uk-button uk-button-primary buy-now border border-white"
            // onClick={() => BuynowClicked(item, qty, item.selectedVariant)}
            onClick={() => {
              item.selectedVariant = variants
              BuynowClicked(item, qty, item.selectedVariant)
            }}
          >
            Buy Now
          </Link>
        </div>
        <div className="uk-child-width-1-2 fixed-buttons buy-cart-static">
          <button style={{ zIndex: 1 }}
            className="mx-1 uk-button uk-button-secondary add-cart"
            onClick={() => {
              item.selectedVariant = variants
              addToCart(item)
            }}
          >
            Add To Cart
          </button>
        </div>
      </div>

    </div >
  );
}

export default ProductDetails;
