import React, { Component, useState, useEffect } from "react";
import {
  SideBySideMagnifier,
} from "react-image-magnifiers";
// import ImageDiv from "./image-div";

// import './assets/css/imageSlider.css'

const ImageSlider = (props) => {

  let imgeSrc = [];
  let vidSrc = [];
  let imgI = 0;
  const exts = [".png", ".jpg", ".webp", ".gif"];

  props.imageArr.forEach(function (img) {
    let ext = img.substring(img.lastIndexOf("."));
    if (exts.includes(ext)) {
      imgeSrc[imgI] = img;
      imgI++;
    } else {
      vidSrc[imgI] = img;
      imgI++;
    }
  });

  const img_fallback = (event) => {
    event.target.parentNode.childNodes.forEach(function (node) {
      if (node.nodeName == "IMG") node.src = window.no_image;
    });
  }
  
  
  if (props.imageArr) {
    return (
      <>
    
        <div className="uk-width-1-1 uk-width-1-2@m uk-padding-remove uk-margin-small-top d-none d-lg-block">
          <div className="uk-position-relative uk-visible-toggle uk-light" data-uk-slideshow="ratio: 4:3; animation: slide;autoplay: true; pause-on-hover:true; autoplay-interval:4000; ">
            <ul className="uk-slideshow-items image-border" data-uk-lightbox>
              {props.imageArr.map((image, index) => (
                <li key={index}>
                  <div
                    className="image-container"
                  >
                    <a href={`${image}`}>
                      {/* <ImageDiv imageUrl={`${image}?resize=498,498&compress=50`}>
                        <img
                          className="image-resize"
                          src={`${image}?resize=498,498&compress=50`}
                          alt={props.itemName}
                          onError={img_fallback}
                          loading="lazy"
                          data-uk-cover
                        />
                      </ImageDiv> */}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
            <div className="uk-position-small uk-position-relative uk-visible-toggle uk-light uk-margin-remove " tabIndex="-1">
              <ul className="uk-thumbnav uk-slider-items uk-child-width-1-4 uk-child-width-1-6@m uk-grid" data-uk-lightbox>
                {props.imageArr.map((image, index) => (
                  <li key={index} data-uk-slideshow-item={index} className="uk-padding-small uk-padding-remove-left">
                    <div className="uk-panel">
                      <a href={`${image}?resize=116,116&compress=50`}>
                        <img
                          src={`${image}?resize=116,116&compress=50`}
                          alt={props.itemName}
                          onError={img_fallback}
                          width="120"
                          height="67"
                        />
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>


        <div className="uk-width-1-1 d-lg-none d-block position-relative">
          <div data-uk-slideshow="animation: push">
            <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">
              <ul className="uk-slideshow-items" data-uk-lightbox>
                {props.imageArr.map((image, index) => (
                  <li key={index} className="">
                    <div
                      className="image-container"
                    >
                      <a href={`${process.env.PUBLIC_URL}${image}`}>

                        <img
                          className="image-resize"
                          src={`${process.env.PUBLIC_URL}${image}`}
                          alt={props.itemName}
                          data-uk-cover
                        />
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
              <a className="uk-position-center-left uk-position-small uk-hidden-hover" href data-uk-slidenav-previous data-uk-slideshow-item="previous"></a>
              <a className="uk-position-center-right uk-position-small uk-hidden-hover" href data-uk-slidenav-next data-uk-slideshow-item="next"></a>
            </div>
            <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
          </div>
        </div>

      </>
    );
  } else {
    return null;
  }
};

export default ImageSlider;