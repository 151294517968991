import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import ProductDetails from './components/product-details';
import ProductInfo from './components/product-info';
import RelatedProducts from './components/related-products';
import TrendingProducts from '../new_home_page/trending_products/product-slider';

import {
    addToCart,
    addToCartUnsafe,
    addToWishlist,
    removeFromWishlist,
    addToCompare,
    getAllPdps,
    addCartToServer
} from "../../actions";
import store from "../../store";

import './css/main.css'
import InstagramWidget from "../new_template_common/instagram_widget/index";

import Promise from "../new_home_page/promise";
import Container from "../system/Container";
// import algoliasearch from 'algoliasearch/lite';

const PdpDetails = (props) => {

    const [state, setState] = useState({
        variants: [],
        images: []
    })
    const [searchResults, setSearchResults] = useState([]);

    function urlParamter() {
        const queryParams = new URLSearchParams(window.location.search);
        const variant_id = queryParams.get('variant_id')
        let selectedVariant = []
        let list = props.allProducts.variants.map((item) => (item.id == variant_id))
        if (list.includes(true))
            selectedVariant = props.allProducts.variants.filter(item => (item.id == variant_id))[0]
        else {
            window.history.replaceState({}, "", window.location.pathname);
            selectedVariant = props.allProducts.variants[0]
        }
        setState({
            variants: selectedVariant,
            images: selectedVariant.image.split(",")
        })
    }

    useEffect(() => {
        return () => {
            store.dispatch({ type: "RECEIVE_PDP", payload: "" })
        }
    }, []);

    useEffect(() => {
        store.dispatch({ type: "RECEIVE_PDP", payload: "" });
        props.getAllPdps(props.match.params.id);
    }, [props.match.params.id]);

    useEffect(() => {
        if (props.allProducts && props.allProducts.variants) {
            // const searchClient = algoliasearch(window.rc.algoliasearch.appId, window.rc.algoliasearch.apiKey);
            // const index = searchClient.initIndex(window.rc.algoliasearch.defaultIndex);

            // index
            //     .search(props.allProducts.category, {
            //         hitsPerPage: 10
            //     })
            //     .then(({ hits }) => {
            //         setSearchResults(hits);
            //     })
            //     .catch(error => console.error(error));

            setSearchResults(window.trending_products.slice(5, 15));

            urlParamter()
        }
    }, [props.allProducts]);

    const setVariant = vari => {
        setState({
            variants: vari,
            images: vari.image.split(",")
        })
    }

    const {
        symbol,
        addToCart,
        addToCartUnsafe,
        addToWishlist,
        removeFromWishlist,
        addToCompare,
        allProducts,
        addCartToServer,
        cartItems,
        wishListItems
    } = props;

    const { variants, images } = state;

    if (allProducts && allProducts.length != 0)
        return (
            <div className="site-main">
                <Helmet>
                    <title>{allProducts.name}</title>
                    <meta name="description" content={variants.short_description} />
                    <meta name="keywords" content={allProducts.name} />
                    <meta property="og:site_name" content="Sunny Diamonds" />
                    <meta property="og:type" content="Summary" />
                    <meta property="og:title" content={allProducts.name} />
                    <meta property="og:description" content={variants.short_description} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:image" content={'https://sunnydiamonds.com' + images[0]} />
                    <meta name="twitter:card" content="Summary" />
                    <meta name="twitter:site" content="@SunnyDiamond_4c" />
                    <meta name="twitter:creator" content="Sunny Diamonds" />
                    <meta name="twitter:title" content={allProducts.name} />
                    <meta name="twitter:image" content={'https://sunnydiamonds.com' + images[0]} />
                    <meta name="twitter:description" content={variants.short_description} />
                    <meta name="robots" content="all" />
                </Helmet>

                <ProductDetails
                    symbol={symbol}
                    item={allProducts}
                    cartItems={cartItems}
                    addToCartClicked={addToCart}
                    addCartToServer={addCartToServer}
                    BuynowClicked={addToCartUnsafe}
                    addToWishlistClicked={addToWishlist}
                    onRemoveFromWishlistClicked={removeFromWishlist}
                    addToCompareClicked={addToCompare}
                    wishListedItem={wishListItems}
                    setVariant={setVariant}
                    history={props.history}
                    itemSelected={variants}
                />

                <ProductInfo item={variants} />

                <RelatedProducts sku={allProducts.sku} />

                <Promise />

                <TrendingProducts searchResults={searchResults} />

                <Container>
                    <InstagramWidget />
                </Container>

            </div>
        );
    else
        return (
            <div className="site-main">
                <div className="uk-section sd-inner-content uk-padding-small product-detail-main md:tw-pt-3 tw-pt-5" style={{ width: "100vw", overflowX: "clip" }}>
                    <div className="uk-container uk-container-xlarge">
                        <div className="card-text placeholder-glow">
                            <br className="d-block d-md-none"></br>
                            <div className="d-flex mb-2 px-2">
                                <p className="placeholder col-md-1 col-2 mr-1 my-0" style={{ height: "1rem" }}></p>
                                <span className="text-muted"> /</span>
                                <p className="placeholder col-md-2 col-4 mx-1 my-0" style={{ height: "1rem" }}></p>
                            </div>
                            <div className="row mx-0" style={{ overflow: "hidden" }}>
                                <div className="placeholder placeholder-thumb col-md-6 col-12 m-2 d-md-none" style={{ height: "16rem" }}></div>
                                <div className="placeholder placeholder-thumb col-md-6 col-12 m-2 d-none d-md-block" style={{ height: "35rem" }}></div>
                                <div className="p-2 flex-fill">
                                    <p className="placeholder placeholder-xl col-12" style={{ height: "4rem" }}></p>
                                    <p className="placeholder col-5 m-0"></p>
                                    <div className="d-flex justify-content-between mt-5">
                                        <p className="placeholder col-3 m-0" style={{ height: "3rem" }}></p>
                                        <p className="placeholder col-3 m-0" style={{ height: "3rem" }}></p>
                                        <p className="placeholder col-3 m-0" style={{ height: "3rem" }}></p>
                                    </div>
                                    <hr />
                                    <div className="d-flex mt-4">
                                        <p className="placeholder col-5 m-0" style={{ height: "3rem" }}></p>
                                        <p className="placeholder col-3 m-0 ml-5" style={{ height: "3rem" }}></p>
                                    </div>
                                    <hr />
                                    <p type="button" className="placeholder btn  btn-promo-code col-2 d-block">Apply</p>
                                    <p className="placeholder col-8"></p>
                                    <p className="placeholder col-7"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
}

const mapStateToProps = (state) => {
    return {
        symbol: state.data.symbol,
        allProducts: state.data.pdp,
        cartItems: state.cartList.cart,
        wishListItems: state.wishlist.list,
    };
};


export default connect(mapStateToProps, { addToCart, addToCartUnsafe, addCartToServer, addToWishlist, removeFromWishlist, addToCompare, getAllPdps })(PdpDetails);